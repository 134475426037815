<template>
    <div class="course-wrapper">
        <div class="course-header">
            <span>{{className}}</span>
            <span>{{courseTitle}}</span>
            <span class="timer">上课中 <span>20:06</span></span>
            <el-button v-if="role === 3" type="primary" size="medium" round @click="endClass">结束上课</el-button>
            <div class="user">
                <div class="user-info">
                    <div class="user-avatar">
                        <img :src="userIcon ? userIcon : require('../../assets/images/user_img.png')" alt="" />
                    </div>
                    <el-dropdown class="header-el-dropdown" @command="logout">
                        <span class="user-text">
                            <span class="user-name text-overflow">{{username}}</span>
                            <i class="el-icon-arrow-down"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item icon="el-icon-switch-button" command="1">退出</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <div class="course-contain">
            <div class="course-contain-left">
                <div class="contain-left-text">
                    <div class="left-button" @click.stop="checked('chapter')"
                         :style="checkedStatus == 'chapter'?'background-color: #26292C;color: #ffffff;':''">
                        <i class="iconfont">&#xe62e;</i>
                        <span>章节</span>
                    </div>
                    <div class="left-button" @click.stop="checked('talk')"
                         :style="checkedStatus == 'talk'?'background-color: #26292C;color: #ffffff;':''">
                        <i class="iconfont">&#xe63b;</i>
                        <span>讨论</span>
                    </div>
                    <div class="left-button" @click.stop="checked('train')"
                         :style="checkedStatus == 'train'?'background-color: #26292C;color: #ffffff;':''">
                        <i class="iconfont">&#xe624;</i>
                        <span>实训</span>
                    </div>
                    <div class="left-button" @click.stop="checked('test')"
                         :style="checkedStatus == 'test'?'background-color: #26292C;color: #ffffff;':''">
                        <i class="iconfont">&#xe681;</i>
                        <span>小测</span>
                    </div>
                    <div class="left-button" @click.stop="checked('sign')"
                         :style="checkedStatus == 'sign'?'background-color: #26292C;color: #ffffff;':''">
                        <i class="iconfont">&#xe636;</i>
                        <span>签到</span>
                    </div>
                    <div class="left-button" @click.stop="checked('guide')"
                         :style="checkedStatus == 'guide'?'background-color: #26292C;color: #ffffff;':''">
                        <i class="iconfont">&#xe601;</i>
                        <span>导图</span>
                    </div>
                    <div class="left-button" @click.stop="checked('blackboard')"
                         :style="checkedStatus == 'blackboard'?'background-color: #26292C;color: #ffffff;':''">
                        <i class="iconfont">&#xe66a;</i>
                        <span>黑板</span>
                    </div>
                </div>
            </div>
            <div class="video-play-head" style="display: flex;flex: 1;" v-if="videoSrc">
                <video :src="videoSrc" class="video-play" controls="controls" autoplay></video>
            </div>
            <!-- <object :data="pdfSrc" type="application/pdf" width="100%" height="100%" v-if="pdfSrc"></object> -->
            <div class="iframe-content" id="iframePdf" v-if="pdfSrc" @mouseover="inIframe">
                <iframe :src="pdfSrc" frameborder="0" width="100%" height="100%"></iframe>
            </div>
            <!--S章节弹窗-->
            <div class="course-chapter-dialog" v-show="checkedStatus === 'chapter'">
                <div class="course-chapter-head">
                    <el-scrollbar style="height: 100%;">
                        <div v-for="(chapter, chapterIndex) in chapterList">
                            <div class="chapter-title">
                                <span>第{{getNum(chapterIndex+1)}}章:</span>
                                <span>{{chapter.name}}</span>
                            </div>
                            <template v-for="(section, sectionIndex) in chapter.children">
                                <div class="section-title" v-if="section.file_name && section.file_preview_path" @click="playResource(section, chapterIndex+'|'+sectionIndex)">
                                    <span><i class="iconfont">&#xe664;</i></span>
                                    <span>第{{getNum(sectionIndex+1)}}节</span>
                                    <span>{{section.name}}</span>
                                    <span class="section-teachering" v-if="getCurrIndex(chapterIndex+'|'+sectionIndex)">正在教<i class="iconfont">&#xe64f;</i></span>
                                    <span v-if="false" :class="getIndexDone(chapterIndex+'|'+sectionIndex) ? 'teacherActive' : 'unTeacherActive'"><i class="iconfont">&#xe71e;</i></span>
                                </div>
                                <div class="true-section" v-else>
                                    <div class="true-section_name">
                                        <span class="true-section_index">第{{getNum(sectionIndex+1)}}节</span>
                                        <span class="true-section_title">{{section.name}}</span>
                                    </div>
                                    <div class="section-title" v-for="(resource, resourceIndex) in section.children" @click="playResource(resource, chapterIndex+'|'+sectionIndex+'|'+resourceIndex)">
                                        <span><i class="iconfont">&#xe664;</i></span>
                                        <span>{{resourceIndex+1}}</span>
                                        <span>{{resource.name}}</span>
                                        <span class="section-teachering" v-if="getCurrIndex(chapterIndex+'|'+sectionIndex+'|'+resourceIndex)">正在教<i class="iconfont">&#xe64f;</i></span>
                                        <span v-else :class="getIndexDone(chapterIndex+'|'+sectionIndex+'|'+resourceIndex) ? 'teacherActive' : 'unTeacherActive'"><i class="iconfont">&#xe71e;</i></span>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </el-scrollbar>
                </div>
            </div>
            <!--E章节弹窗-->
            <!--S讨论-->
            <div class="course-talk" v-show="checkedStatus === 'talk'">
                <div class="course-talk-head">
                    <span>讨论</span>
                    <span @click="checked('talk')"><i class="iconfont">&#xe8e7;</i></span>
                </div>
                <div class="course-talk-main">
                    <div v-for="talk in talkList" :key="talk.talkId">
                        <div class="talk-main-name">
                            <img :src=talk.talkImg />
                            <div class="name-teacher" v-show="talk.talkRole == 3">老师</div>
                            <div class="talk-name">{{talk.talkName}}</div>
                        </div>
                        <div class="talk-context">
                            {{talk.talkContent}}
                        </div>
                    </div>
                </div>
                <div class="talk-middle">
                    <i class="iconfont">&#xe75a;</i>
                    <i class="iconfont">&#xe643;</i>
                    <i class="iconfont">&#xe61f;</i>
                </div>
                <div class="talk-space">
                    <div class="talk-input">
                        <el-input type="textarea" v-model="input" rows="8"></el-input>
                    </div>
                    <div class="talk-button">
                        <el-button>发送</el-button>
                    </div>
                </div>
            </div>
            <!--E讨论-->
            <!--S实训-->
            <div class="course-talk" v-show="checkedStatus === 'train'">
                <div class="course-talk-head">
                    <span>实训</span>
                    <span class="closeDialog" @click="checked('train')"><i class="iconfont">&#xe8e7;</i></span>
                </div>
                <div class="course-talk-main">
                    <div v-for="train in trainList">
                        <div class="talk-main-name">
                            <img :src=train.teacherImg />
                            <div class="name-teacher">老师</div>
                            <div class="talk-name">{{train.teacherName}}</div>
                            <div class="train-dataTime">{{train.createDate}}</div>
                        </div>
                        <div class="talk-context">
                            {{train.teacherName}}发布了实训任务：{{train.trainTitle}}
                        </div>
                        <div class="train-content">
                            文件:<span>{{train.file}}</span>
                        </div>
                    </div>
                </div>
                <div class="talk-middle"></div>
                <div class="talk-space">
                    <div class="talk-input">
                        <el-form label-width="80px">
                            <div class="train-title-input">
                                <el-form-item label="实训标题">
                                    <el-input v-model="trainTitle"></el-input>
                                </el-form-item>
                                <el-form-item label="实训文件">
                                    <div class="train-upload-file">
                                        <el-input v-model="trainFileName"></el-input>
                                        <el-upload action="">
                                            <el-button type="primary">上传文件</el-button>
                                        </el-upload>
                                    </div>
                                </el-form-item>
                            </div>
                        </el-form>
                    </div>
                    <div class="talk-button">
                        <el-button>保存</el-button>
                    </div>
                </div>
            </div>
            <!--E实训-->
            <!--S小测-->
            <div class="course-talk" v-show="checkedStatus === 'test'">
                <div class="course-talk-head">
                    <span>小测</span>
                    <span class="closeDialog" @click="checked('test')"><i class="iconfont">&#xe8e7;</i></span>
                </div>
                <div class="course-talk-main">
                    <div v-for="test in testList">
                        <div class="talk-main-name">
                            <img :src=test.teacherImg />
                            <div class="name-teacher">老师</div>
                            <div class="talk-name">{{test.teacherName}}</div>
                            <div class="train-dataTime">{{test.createDate}}</div>
                        </div>
                        <div class="train-content">
                            <span>{{test.testContent}}</span>
                        </div>
                    </div>
                </div>
                <div class="talk-middle">
                    <i class="iconfont">&#xe75a;</i>
                    <i class="iconfont">&#xe643;</i>
                    <i class="iconfont">&#xe61f;</i>
                    <i class="iconfont">&#xe602;</i>
                    <span class="test-selectPaper">选择试卷</span>
                </div>
                <div class="talk-space">
                    <div class="test-input">
                        <el-input type="textarea" v-model="testInput" rows="8"></el-input>
                    </div>
                    <div class="talk-button">
                        <el-button>保存</el-button>
                    </div>
                </div>
            </div>
            <!--E小测-->
            <el-dialog
                    title="签到"
                    :visible.sync="signDialog"
                    width="25%">
                <div class="sign-content">
                    <div class="sign-title">已签到人数/总人数</div>
                    <div class="sign-num">20/30</div>
                    <div class="sign-btn">
                        <el-button type="primary" size="small" @click="signDialog = false">确 定</el-button>
                    </div>
                </div>
            </el-dialog>
            <!--S黑板-->
            <el-dialog title="课堂黑板" :visible.sync="dialogVisible" width="100%" style="height: 100%;" :fullscreen="true" class="blackboard-dialog" @close="closeBlackboard">
                <iframe :src="blackBoardSrc" frameborder="0" width="100%" height="100%"></iframe>
            </el-dialog>
            <!--E 黑板-->
            <!--S 思维导图-->
            <el-dialog title="思维脑图" :visible.sync="dialogMindMap" width="100%" :fullscreen="true" class="blackboard-dialog" @close="closeBlackboard">
                <iframe :src="mindMapSrc" frameborder="0" width="100%" height="100%"></iframe>
            </el-dialog>
            <!--S 思维导图-->
        </div>
    </div>
</template>

<script>
    export default {
        name: "courseIndex",
        created() {
            this.blackBbUrl = this.$api.blackBbUrl;
            this.mindMapUrl = this.$api.mindMapUrl;
			this.getCourseInfo(this.$route.query.id);
        },
        data() {
            return {
                id: Number(this.$route.query.id) || '',
                recordId: Number(this.$route.query.recordId) || '',
                className: this.$route.query.className || '',
                testInput:'第一次考试链接：www.baiduc.com',
                trainFileName:'',
                trainTitle:'',
                input:'',
                checkedStatus: '',
                signDialog: false,
                testList:[
                    {
                        teacherImg:require("../../assets/logo.png"),
                        teacherName:'陈老师',
                        createDate:'2020-12-12 12:12:12',
                        testContent:'第一次考试链接：www.baiduc.com'
                    },
                    {
                        teacherImg:require("../../assets/logo.png"),
                        teacherName:'陈老师',
                        createDate:'2020-12-12 12:12:12',
                        testContent:'第一次考试链接：www.baiduc.com'
                    },
                    {
                        teacherImg:require("../../assets/logo.png"),
                        teacherName:'陈老师',
                        createDate:'2020-12-12 12:12:12',
                        testContent:'第一次考试链接：www.baiduc.com'
                    },
                ],
                talkList:[
                    {
                        talkRole:3,
                        talkId:1,
                        talkName:'陈老师',
                        talkImg: require("../../assets/logo.png"),
                        talkContent:"同学们这个题目要认真学习哈同学们这 个题目要认真学习哈。同学们，有问题请提问哈哈哈",
                    },
                    {
                        talkRole:4,
                        talkId:2,
                        talkName:'小王',
                        talkImg: require("../../assets/logo.png"),
                        talkContent:"好的，谢谢了老师",
                    },
                    {
                        talkRole:4,
                        talkId:3,
                        talkName:'小明',
                        talkImg: require("../../assets/logo.png"),
                        talkContent:"好的，谢谢了老师",
                    }
                ],
                trainList:[
                    {
                        teacherImg:require("../../assets/logo.png"),
                        teacherName:'陈老师',
                        createDate:'2020-12-12 12:12:12',
                        trainTitle:'如何设计图片',
                        file:'如何设计图片.doc'
                    },
                    {
                        teacherImg:require("../../assets/logo.png"),
                        teacherName:'陈老师',
                        createDate:'2020-12-12 12:12:12',
                        trainTitle:'如何设计图片',
                        file:'如何设计效果图.doc'
                    },
                    {
                        teacherImg:require("../../assets/logo.png"),
                        teacherName:'陈老师',
                        createDate:'2020-12-12 12:12:12',
                        trainTitle:'如何设计图片',
                        file:'如何设计表格.doc'
                    },
                ],
                chapterList:[],
                dialogVisible: false,
                blackBoardSrc: '',
                blackBbUrl: '',
                dialogMindMap: false,
                mindMapSrc: '',
                mindMapUrl: '',
				indexCurrent: '',
				indexDone: [],
				videoSrc: '',
				pdfSrc: '',
                role: Number(localStorage.getItem('role')),
                username: '',
                userIcon: require('../../assets/images/user_img.png'),
                courseTitle: ''
            }
        },
        watch: {
            $route: (newRoute) => {
                console.log(newRoute)
                this.id = newRoute.query.id
            }
        },
        mounted() {
            window.addEventListener('beforeunload', (event) => {
                event = event || window.event;
                if (event) {
                    event.returnValue = '是否结束上课！';
                } else {
                    return '是否结束上课！';
                }
            });
            this.username = localStorage.getItem('sUsername');
            let userIcon = localStorage.getItem('sIcon');
            if (userIcon != '' && userIcon != undefined) {
                this.userIcon = userIcon;
            };
        },
        methods: {
            inIframe() {
                if (this.checkedStatus === 'chapter') {
                    this.checked('');
                }

            },
            logout(command) {
                this.$shttp.axiosGet(this.$api.logout, (res) => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: res.msg,
                            onClose: ()=> {
                                localStorage.removeItem('sToken');
                                localStorage.removeItem('sUsername');
                                localStorage.removeItem('role');
                                if (this.$route.path === '/'){
                                    window.location.reload();
                                } else {
                                    this.$router.push('/');
                                }
                            }
                        })
                    } else {
                        this.$message({
                            type: 'warning',
                            duration: 1000,
                            message: res.msg,
                            onClose: ()=> {
                                localStorage.removeItem('sToken');
                                localStorage.removeItem('sUsername');
                                localStorage.removeItem('role');
                                if (this.$route.path === '/'){
                                    window.location.reload();
                                } else {
                                    this.$router.push('/');
                                }
                            }
                        })
                    }
                })
            },
            checked(flag) {
                this.checkedStatus === flag ? this.checkedStatus = '' : this.checkedStatus = flag;
                switch (flag) {
                    case 'sign':
                        this.signDialog = true;
                        break;
                    case 'guide':
                        this.mindMapSrc = this.mindMapUrl;
                        this.dialogMindMap = true;
                        break;
                    case 'blackboard':
                        this.blackBoardSrc = this.blackBbUrl;
                        this.dialogVisible = true;
                        break;
                }
            },
            closeBlackboard() {
                this.blackBoardSrc = '';
            },
            closeMindMap() {
                this.mindMapSrc = '';
            },
			getCourseInfo(id) {
				let params = { id };
				this.$shttp.axiosGetBy(this.$api.courseContent, params, (res) => {
					this.chapterList = res.data.course_content;
					this.courseTitle = res.data.course_name;
					let sectionSt = this.chapterList[0].children[0];
					if (sectionSt.file_name && sectionSt.file_preview_path) {
						this.playResource(sectionSt, '0|0');
					} else {
						this.pdfSrc = sectionSt.children[0].file_preview_path;
						this.playResource(sectionSt.children[0], '0|0|0');
					}
				}, (err) => {
					console.log(err);
				});
			},
            //转换章节
            getNum(num){
                return this.$common.getNumStr(num);
            },
			playResource(item, index) {
				this.pdfSrc = '';
				this.videoSrc =  '';
				this.indexCurrent = index;
				if (this.indexDone.length) {
					let str = this.indexDone.join();
					if (str.indexOf(index) === -1) {
						this.indexDone.push(index);
					}
				} else {
					this.indexDone.push(index);
				}
				
				if (item.resource_file_type === 'mp4') {
					this.videoSrc = item.file_preview_path;
				} else {
					this.pdfSrc = item.file_preview_path;
				}
			},
			getCurrIndex(index) {
				return this.indexCurrent === index;
			},
			getIndexDone(index) {
				let str = this.indexDone.join();
				if (str.indexOf(index) === -1) {
					return false;
				} else {
					return true;
				}
			},
            endClass() {
                this.$confirm('确定结束上课吗？', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let params = {
                        course_record_id: this.recordId,
                        chapter_sign: this.indexCurrent.split('|')[0],
                    }
                    this.$shttp.axiosPost(this.$api.endingCourse, params, (res) => {
                        if (res.code === 200) {
                            this.$router.push('/myclass')
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消结束上课！')
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .course-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .course-header {
            background: #fff;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 16px;
            position: relative;
            span {
                margin-right: 10px;
                color: #1989FA;
            }
            .timer {
                 color: #FF0000;
                 margin: 0 50px 0 20px;
                span {
                    color: #FF0000;
                    margin-left: 20px;
                }
            }
            .user {
                position: absolute;
                top: 50%;
                right: 40px;
                transform: translateY(-50%);
                .user-info {
                    display: flex;
                    align-items: center;
                    .user-name {
                        font-size: 16px;
                        color: #606266;
                    }
                    .el-icon-arrow-down {
                        color: #606266;
                    }
                }
                .m-userinfo {
                    a {
                        display: block;
                        font-size: 16px;
                        color: #666;
                    }
                }
                .user-avatar {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    overflow: hidden;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    border: 1px solid #ddd;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
            .user .header-el-dropdown{
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 10px 0;
                .user-text {
                    line-height: 40px;
                    margin-left: 10px;
                }
                img {
                    display: block;
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                }
            }
        }
        .course-contain {
            height: 1%;
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            & * {
                box-sizing: border-box;
            }
            .iframe-content {
                display: flex;
                flex: 1;
                height: 100%;
            }
        }
    }
    .sign-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .sign-title, .sign-num, .sign-btn {
            margin: 10px 0;
        }
    }
    .blackboard-dialog {
        ::v-deep .el-dialog__body {
            height: calc( 100% - 60px);
            padding: 0 10px;
        }
    }
	.true-section {
		margin-top: 15px;
		box-sizing: border-box;
		& * {
			box-sizing: border-box;
		}
		.true-section_name {
			padding-left: 20px;
			color: #999;
			.true-section_index {
				padding-right: 10px;
			}
		}
	}

    .course-contain-left {
        width: 58px;
        background-color: #1C1F21;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .contain-left-text {
            width: 100%;
            height: 392px;
            display: flex;
            flex-direction: column;
        }
    }



    .left-button {
        height: 56px;
        color: #B3B3B3;
        display: flex;
        flex-direction: column;
        cursor: pointer;
    }

    .left-button i {
        margin: 9px auto 0;
    }

    .left-button span {
        margin: 0 auto;
    }

    /*start视频播放*/
    .video-play-head {
        height: 100%;
        width: calc(100% - 58px)

    }

    .video-play {
        height: calc(100%);
        background-color: #000000;
        width: 100%;
    }
    /*end视频播放*/

    /*start章节弹窗*/
    .course-chapter-dialog{
        /*width: 400px;*/
        height: 100%;
        background-color: #26292C;
        position: fixed;
        left: 58px;
        z-index: 10;
        overflow: auto;
        color: #ffffff;
    }
    .course-chapter-head{
        height: calc(100% - 80px);
        /*width: 350px;*/
        margin:7px 25px 20px 20px;
		::v-deep .el-scrollbar__wrap {
			overflow-x: hidden;
		}
    }
    .chapter-title{
        padding-top: 23px;
        text-align: left;
        width: 240px;
        color: #FFFFFF;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }
    .chapter-title span:last-child{
        margin-left: 5px;
    }
    .section-title{
        width: 360px;
        cursor: pointer;
        padding-top: 16px;
        padding-left: 20px;
        color: #999999;
    }
    .section-title span:nth-child(2){
        margin-left: 8px;
        padding-top: -2px;
    }
    .section-title span:nth-child(3){
        margin-left: 12px;
        width: 210px;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }
    .section-title .section-teachering{
        float: right;
        margin-top: 3px;
        margin-right: 10px;
        color: #FF0000;
    }
    .teacherActive{
        float: right;
        margin-top: 3px;
        margin-right: 10px;
        -webkit-text-fill-color: #3296FA;
    }
    .unTeacherActive{
        float: right;
        margin-top: 3px;
        margin-right: 10px;
    }
    .section-teachering i{
        margin-left: 10px;
    }
    /*end章节弹窗*/
    /*start签到弹窗*/
    .course-sign-dialog{
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 11;
    }
    .course-sign-dialog .sign-contain {
        width: 450px;
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        z-index: 12;
        background: #fff;
    }
    .sign-contain-head{
        height: 46px;
        line-height: 46px;
        border-bottom: 1px solid #EAEAEA;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .sign-contain-head span:first-child{
        margin-left: 32px;
    }
    .sign-contain-head span:last-child{
        margin-right: 16px;
        cursor: pointer;
    }
    .sign-contain-main{
        height: 167px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .sign-contain-main p:first-child{
        margin-top: 26px;
    }
    .sign-contain-main .el-button{
        height: 32px;
        width: 100px;
        line-height: 0;
        background-color:#3296FA;
        color: #ffffff;
    }
    /*end签到弹窗*/
    /*Start讨论*/
    .course-talk{
        width: 478px;
        height: 100%;
    }
    .course-talk-head{
        height: 45px;
        line-height: 45px;
        display: flex;
        justify-content: space-between;
    }
    .course-talk-head span:first-child{
        margin-left: 31px;
    }
    .course-talk-head span:last-child{
        margin-right: 28px;
        cursor: pointer;
    }
    .course-talk-main{
        height: calc(100% - 325px);
        overflow: auto;
    }
    .talk-main-name{
        margin-left: 31px;
        display: flex;
        flex-direction: row;
        width: 320px;
        margin-top:15px;
    }

    .talk-main-name img{
        width: 24px;
        height: 24px;
        padding-top: 3px;
    }
    .talk-main-name .name-teacher{
        background-color: #3296FA;
        margin-left: 10px;
        border-radius:2px;
        color: #ffffff;
        padding: 4px 6px 4px 6px;
    }
    .talk-main-name .talk-name{
        margin-left: 15px;
        padding-top: 3px;
        color: #3296FA;
    }
    .talk-context{
        margin-top: 5px;
        margin-left: 30px;
        width: 320px;
        word-wrap:break-word;
        word-break:normal;
    }
    .talk-middle{
        height: 32px;
        line-height: 32px;
        background-color: #EAEAEA;
        padding-left: 30px;
        color: #8e8e8e;
        font-size: 16px;
    }
    .talk-middle i{
        cursor: pointer;
    }
    .talk-middle i:nth-child(2){
        margin-left: 15px;
    }
    .talk-middle i:nth-child(3){
        margin-left: 15px;
    }
    .talk-middle i:nth-child(4){
        margin-left: 15px;
    }
    .talk-space{
        height: 238px;
        overflow: auto;
    }
    .talk-input{
        height: calc(100% - 40px);
        overflow: auto;
    }
    .talk-button{
        margin-right: 20px;
        margin-top: 5px;
        float: right;
    }
    .talk-button .el-button{
        color: #ffffff;
        background: #3296FA;
        height: 35px;
        width: 110px;
        line-height: 0;
    }
    /*End讨论*/
    /*Start实训*/
    .train-content{
        margin-top: 5px;
        margin-left: 30px;
    }
    .train-content span{
        color: #3296FA;
        margin-left: 5px;
    }
    .train-dataTime{
        padding-top: 5px;
        margin-left: 10px;
    }
    .train-title-input{
        margin-top: 23px;
        margin-right: 44px;
    }
    .train-upload-file{
        display: flex;
        flex-direction: row;
    }
    /*End实训*/
    /*start小测*/
    .test-selectPaper{
        float: right;
        margin-right: 20px;
        cursor: pointer;
    }
    .test-input{
        height: calc(100% - 40px);
        overflow: auto;
    }
    /*end小测*/
</style>
<style>
    .talk-input .el-textarea__inner{
        border: none;
    }
    .train-upload-file .el-button{
        margin-left: 20px;
    }
    .test-input .el-textarea__inner{
        border: none;
        color: #3296FA;
    }
</style>